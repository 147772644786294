@tailwind base;
@tailwind components;
@tailwind utilities;

@import './react-datepicker.css';
@import '../node_modules/react-quill/dist/quill.snow.css';

@layer utilities {
  @layer responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.react-datepicker__input-container input {
  line-height: 1.6rem !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #8080805c;
  border-radius: 20px;
}

/* For Chrome, Safari and Opera */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  background-color: white !important;
  color: rgb(156 163 175) !important;
}

/* For Firefox */
input:autofill {
  background: white; 
}

.creatorScrollbar::-webkit-scrollbar {
  display: none;
}

.cropper-container {
  width: 50%;
  height: 50%;
}

.creatorScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

[type='checkbox']:checked {
  background-color: #74BB17 !important;
}

[type='checkbox'] {
  background-color: #D4343E !important;
}

:root {
  --numbers-text-color: black;
  --hand-line-color: rgb(255, 166, 0);
  --hand-circle-outer: rgba(255, 166, 0, 0.568);
  --hand-minute-circle: rgb(255, 166, 0);
  --hand-hour-circle: rgb(255, 166, 0);
  --top-selected-color: rgb(255, 166, 0);
}

.css-1s2u09g-control {
  max-height: 34px !important;
  min-height: 34px !important;
  border-radius: 7px !important;
  margin-top: 4px !important;
}

.css-1insrsq-control {
  max-height: 34px !important;
  min-height: 34px !important;
  border-radius: 7px !important;
  margin-top: 4px !important;
  background-color: #e8eaed !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

/* Remove arrow from Phone Number input */
.phone-input::-webkit-outer-spin-button,
.phone-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phone-input[type=number] {
  -moz-appearance: textfield;
}

/* Quill editor */
.ql-editor {
  height: 9.5rem !important;
  color: #9CA3AF !important;
}

.ql-toolbar.ql-snow {
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.ql-container.ql-snow {
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}

.ql-toolbar {
  position: relative;
  top: 9.5rem;
  width: 100%;
  margin-top: -70px;
  @media (min-width: 768px) {
    margin-top: -44px;    
  }
  transform: translateY(100%);
}

.hideNumberFieldArrows input::-webkit-outer-spin-button,
.hideNumberFieldArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hideNumberFieldArrows input[type=number] {
  -moz-appearance: textfield;
}

.react-tel-input input[type=tel] {
  width: -webkit-fill-available;
}

/* live card */
.beemg_wrap {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.beemg_block {
  transform: translateZ(0px);
  transition: transform 0.5s;
  transform-origin: 0 0;
}

.beemg_block:hover {
  transform: translateZ(50px);
}

.beemg_background {
  background-position: right 114px top -177px;
  background-size: 40rem 27rem;
}

.eventImages::-webkit-scrollbar {
  height: 3px;
}

.eventImages::-webkit-scrollbar-thumb {
  background-color: #8080805c;
  border-radius: 20px;
}

.tippy-box[data-theme~='BeeMG-orange'] {
  background-color: #F6851F;
  color: white;
}

.tippy-box[data-theme~='BeeMG-orange'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #F6851F;
}

.tippy-box[data-theme~='BeeMG-orange'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #F6851F;
}

.tippy-box[data-theme~='BeeMG-orange'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #F6851F;
}

.tippy-box[data-theme~='BeeMG-orange'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #F6851F;
}

.tippy-box[data-theme~='BeeMG-orange']>.tippy-svg-arrow {
  fill: #F6851F;
}

.tippy-box[data-theme~='BeeMG-gray'] {
  background-color: #EEEEEE;
  color: black;
}

.tippy-box[data-theme~='BeeMG-gray'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #EEEEEE;
}

.tippy-box[data-theme~='BeeMG-gray'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #EEEEEE;
}

.tippy-box[data-theme~='BeeMG-gray'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #EEEEEE;
}

.tippy-box[data-theme~='BeeMG-gray'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #EEEEEE;
}

.tippy-box[data-theme~='BeeMG-gray']>.tippy-svg-arrow {
  fill: #EEEEEE;
}

.tippy-box[data-theme~='BeeMG-yellow'] {
  background-color: #FDC20F;
  color: black;
  font-size: 0.8rem;
}

.tippy-box[data-theme~='BeeMG-yellow'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #FDC20F;
}

.tippy-box[data-theme~='BeeMG-yellow'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #FDC20F;
}

.tippy-box[data-theme~='BeeMG-yellow'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #FDC20F;
}

.tippy-box[data-theme~='BeeMG-yellow'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #FDC20F;
}

.tippy-box[data-theme~='BeeMG-yellow']>.tippy-svg-arrow {
  fill: #FDC20F;
}

.react-datepicker__day--selecting-range-start {
  background-color: #fcd34d;
}

.weekend-calender-text-color {
  color: red;
}
.left-rounded-calender {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.right-rounded-calender {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.input-style {
  width: 2.5em; /* width equivalent */
  border-radius: 0.25rem; /* border-radius equivalent */
  margin: 0 0.2em; /* horizontal margin */
  padding: 0.5em; /* padding */
  font-size: 1.25em; /* font size */
  font-weight: 600; /* font weight */
  color: #374151; /* text color */
}
.cropper-container {
  width: 300px;
  height: 300px;
}

#policy-content-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}
#policy-content-scroll::-webkit-scrollbar-thumb {
  background-color: #FDC20F;
  height: 50%
}

#acceptTerms {
  width: 20px;
  height: 20px;
  border: 2px solid black !important;
  background-color: #f5f5f5 !important;
  cursor: pointer;
}
#acceptTerms:checked {
  background-color: #FDC20F  !important; /* Fills the checkbox with color when checked */
}


ul, ol {
  list-style: none !important;
}
.list-inherited ul, .list-inherited ol {
  all: revert !important;
}